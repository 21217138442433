<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Edit Note
        </h1>
        <SlideoutTabs default_width="half" :model="{id:item.id,type:'Note'}" :contents="tab_content"  orientation="top-0 bottom-0 right-0" position="fixed">
        </SlideoutTabs>
        <div class="" v-if="item">
            <FormTemplate @response="formResponse" button="Save" method="post" action="/note" :formdata="formData">
                <div class="h-full w-full overflow-auto	">
                    <wysiwyg v-model="item.content" />
                </div>
            </FormTemplate>
        </div>
    </div>
</template>

<script>
import View from '../../../mixins/View'
import moment from 'moment'

export default {
    name: 'Note',
    mixins: [View],
    data: () => ({
        parameter: 'note_id',
        action: '/note/',
        tab_content: {
        }
    }),
    computed: {
        formData() {
            return {
                'note':this.item,
            }
        },
    },
    props: [],
    methods: {
        getDate(datetime) {
            return moment(datetime).format('MMMM Do YYYY');
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
